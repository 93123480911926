import React, {useEffect, useState} from "react"

import SEO from "../../components/seo"

import { Form } from "react-bootstrap"
import {FormattedMessage} from "react-intl";
import instance from "../../components/api/httpclient";
import {changeLocale, navigate, useIntl} from "gatsby-plugin-intl";

const Language = (navigateTo) => {
  const intl = useIntl();
  const [language, setLanguage] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      setCurrentUser(user);
      setLanguage(user.language);
    }
  }, []);


  const sendLanguage = (event) => {
    console.log(language);
    instance.post('/language',
      {
        language: language
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      }).then(function (response) {

      if (typeof window !== 'undefined' ) {
        localStorage.setItem('currentUser', JSON.stringify(response.data.user));

        changeLocale(language);
        //navigate(navigateTo.navigateTo);

      }
    });
    event.preventDefault();
  };

    return(
        <div>
          <SEO title="Language preferences"/>


          <div className="white-container mb-5">

            <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "my_preferences.language_preferences" })}</h1>

            <Form>
              <Form.Group>
                <div className="d-flex flex-wrap">
                  <div className="mr-4">
                    <div className="radio-custom">
                      <input type="radio" id="languageFR" name="language" value="fr-BE" checked={language === 'fr-BE'} className="form-radio-input" onChange={(event) => setLanguage(event.target.value)}/>
                      <label htmlFor="languageFR" title="" className="form-radio-label">{intl.formatMessage({id: "generic.forms.form_options.languages.fr"})}</label>
                    </div>
                  </div>

                  <div className="mr-4">
                    <div className="radio-custom">
                      <input type="radio" id="languageNL" name="language" value="nl-BE" checked={language === 'nl-BE'} className="form-radio-input" onChange={(event) => setLanguage(event.target.value)}/>
                      <label htmlFor="languageNL" title="" className="form-radio-label">{intl.formatMessage({id: "generic.forms.form_options.languages.nl"})}</label>
                    </div>
                  </div>

                </div>
              </Form.Group>


              <Form.Group className="mb-0">
                <button type="submit"
                        className={language ? "button red w-100 mx-auto" : " disabled button red w-100 mx-auto" }
                        onClick={((event) => sendLanguage(event))}
                        disabled={!language}>
                  <FormattedMessage id="generic.forms.form_buttons.submit"/>
                </button>
              </Form.Group>

            </Form>

          </div>

        </div>
    )
};

export default Language
